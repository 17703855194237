// @ts-ignore - socker-io-client does not have type definitions
import io from 'socket.io-client/dist/socket.io.js'
import config from '../config'

const useSocketService = false
const WS_BASE = useSocketService ? config.WS_BASE : config.API_BASE

const baseUrl = new URL(WS_BASE)
const options = { path: `${baseUrl.pathname}/socket.io`, transports: ['websocket'] }

const socket = {
  reports: io('/reports', options),
  appointments: io('/appointments', options),
  orders: io('/orders', options),
  requests: io('/carrierRequests', options)
}

export default socket
